<template>
  <div>
    <div class="needlogin-tips">You need to login first to see this page!</div>
    <div class="needlogin-timer"><span class="needlogin-jump" @click="jumpToLogin">Click here</span> or redirect to login page in {{waitTime}} seconds...</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      waitTime: 5,
      timer: null
    }
  },
  mounted () {
    this.timerGo()
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  methods: {
    jumpToLogin () {
      this.$router.push({
        name: 'Login',
        query: this.$route.query
      })
    },
    timerGo () {
      let Tself = this
      Tself.timer = setTimeout(() => {
        Tself.waitTime--
        clearTimeout(Tself.timer)
        if (Tself.waitTime === 0) {
          Tself.jumpToLogin()
        } else {
          Tself.timerGo()
        }
      }, 1000)
    }
  }

}
</script>

<style lang="less">
.needlogin-tips {
  font-size: 30px;
  font-weight: 700;
  margin-top:100px;
}
.needlogin-timer {
  font-size: 24px;
  margin-top: 20px;
}
.needlogin-jump {
  cursor: pointer;
  text-decoration: underline;
}

</style>